import React from 'react'
import styled from 'styled-components'
import '../../App.css'

const Container = styled.div`
 background-color: black;
 padding: 1rem 2rem;
 border-radius: 0.75rem;
 
 @media (max-width: 768px) {
  width: 85vw !important;
 }
`
const Text = styled.div` 
color:white;
display: grid !important;
grid-template-columns: 1fr 5fr ;
justify-content: start;
align-items: center;
text-align: start;
`


export default function Pitch() {
  return (
    <Container>
      <Text>
        <i class="fa-solid fa-money-bill-1" style={{fontSize:"1.5rem"}}></i>
        <h4>משיכת כספים מכל בית השקעות.</h4>
        <i className="fa-solid fa-question" style={{fontSize:"1.5rem"}}></i>
        <h4>איתור כספים אבודים.</h4>
        <i className="fa-solid fa-arrows-rotate" style={{fontSize:"1.5rem"}}></i>
        <h4>⁠החזרי מס, תיאום מס.</h4>
        <i className="fa-solid fa-gifts" style={{fontSize:"1.5rem"}}></i>
        <h4>⁠קבלת הטבות מול מס הכנסה.</h4>
        <i className="fa-solid fa-user-tie" style={{fontSize:"1.5rem"}}></i>
        <h4>שירות מקצועי ברמה גבוהה</h4>
        <i className="fa-solid fa-tag" style={{fontSize:"1.5rem"}}></i>
        <h4>במחיר נמוך והוגן.</h4>
      </Text>
    </Container>
  )
}
