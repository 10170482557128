import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Select from '@mui/material/Select';
import axios from 'axios';
import '../../App.css';





// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="/">
//         TAXBACK
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  palette: {
    yellow: "#ffd700"
  }
});

export default function SignUp() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const be_url= process.env.REACT_APP_BACKEND_URL
    const config = {
      headers: {
          'Content-Type': 'application/json',
          // 'Accept':'*/*',
          // 'Access-Control-Allow-Origin': '*',
          // "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          // "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
      }
    };
    const body_content = {
      "phone": data.get('phone'),
      "choice": choice,
      "first_name": data.get('firstName'),
      "last_name": data.get('lastName'),
    }
    try {
      const response = await axios.post(be_url, body_content, config);
      console.log(response.data);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email.');
    }
  };
  const [choice, setChoice] = React.useState('החזר מס');

  const handleChange = (event) => {
    setChoice(event.target.value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container className='form-container' maxWidth="xs" sx={{
        margin:"0 !important",
        backgroundColor:'black',
        padding: "2rem 1rem 1rem 1rem",
        borderRadius: '0.75rem',

      }}>
        <CssBaseline />
        <Box
          sx={{
            // marginTop: 8,
            margin:"0 !important",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0,  }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  variant="filled"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="שם פרטי"
                  autoFocus
                  // InputLabelProps={{className: "input_label"}}
                  style={{direction:"rtl", background:"white", borderRadius:"0.75rem"}}
                  // color="secondary"
                />
              </Grid> 
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="שם משפחה"
                  name="lastName"
                  variant="filled"
                  autoComplete="family-name"
                  style={{direction:"rtl", background:"white", borderRadius:"0.75rem"}}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="מספר טלפון"
                  name="phone"
                  variant="filled"
                  autoComplete="phone"
                  style={{direction:"rtl", background:"white", borderRadius:"0.75rem"}}
                />
              </Grid>
              <Grid item xs={12} dir="rtl">
                <FormControl fullWidth variant="filled">
                  <InputLabel id="choice-label">שירות</InputLabel>
                  <Select
                    labelId="choice-label"
                    id="choice"
                    value={choice}
                    label="choice Choice"
                    onChange={handleChange}
                    style={{direction:"rtl", background:"white", borderRadius:"0.75rem"}}
                  >
                    <MenuItem value="החזר מס">החזרי מס</MenuItem>
                    <MenuItem value="איתור כספים">איתור כספים</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              className='my-button'
            >
              שלח פניה
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}