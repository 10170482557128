import './App.css';
import HomePage from './components/Home';

function App() {
  return (
    <div className="App" dir="rtl">
      <HomePage/>
    </div>
  );
}

export default App;
