import React from 'react'
import styled from 'styled-components';

// Define the styled components
const Container = styled.div`
display: flex;
flex-direction: row-reverse;
justify-content: space-around;
align-items: center;
gap:1rem;
padding: 1rem 0rem;
background-color: #000000;
`;

const StyledImage = styled.img`
width: 10rem; 

@media (max-width: 768px) {
  width: 7rem;
}
`;

const StyledHeading = styled.h1`
font-size: 4rem;
color: #FFD700;

@media (max-width: 768px) {
  font-size: 2rem;
}
`;

// Component that uses the styled components
const Header = ({ imageUrl, title }) => (
  <Container>
    <StyledImage src={imageUrl} alt={title} />
    <StyledHeading>{title}</StyledHeading>
  </Container>
);

export default Header;